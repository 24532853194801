<template>
    <!-- Usando v-if para garantir que o modal seja montado/desmontado corretamente -->
    <div v-if="visible" class="modal-overlay" @click.self="hideModal">
      <div class="modal-content">
        <h4>{{ message }}</h4>
        <div class="spinner"></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AppModalCarregamento',
    data() {
      return {
        visible: false,
        message: 'Carregando...' // Mensagem padrão
      };
    },
    methods: {
      // Método para exibir o modal de carregamento
      show(message = 'Carregando...') {
        this.message = message;
        this.visible = true;
      },
      // Método para esconder o modal de carregamento
      hideModal() {
        this.visible = false;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Estilo para o overlay do modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Garante que o modal fique acima de outros elementos */
  }
  
  /* Estilo para o conteúdo do modal */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
  
  /* Estilo para o spinner (círculo giratório) */
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db; /* Cor do spinner */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto 10px auto; /* Centraliza o spinner */
  }
  
  /* Animação do spinner */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Estilo para a mensagem */
  .modal-content p {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  </style>