// src/router.js
import Vue from 'vue';
import Router from 'vue-router';

// Importando os componentes
/* Site */
import HomePageSite from './pages/Site/HomePage.vue';
import RegistroPageSite from './pages/Site/RegistroPage.vue';
import LoginPageSite from './pages/Site/LoginPage.vue';
import ContatoPageSite from './pages/Site/ContatoPage.vue';

/* Painel */
import HomePagePainel from './pages/Painel/HomePage.vue';

/* Suporte */
import SuportePainelPage from './pages/Suporte/SuportePainelPage.vue';
import SuportePage from './pages/Suporte/SuportePage.vue';

/* Blog */
import BlogPage from './pages/Blog/HomePage.vue';
import PostPage from './pages/Blog/PostPage.vue';


// Usando o Vue Router
Vue.use(Router);

const routes = [
  {
    path: '/',
    component: HomePageSite
  },
  {
    path: '/painel',
    component: HomePagePainel
  },
  {
    path: '/registro',
    component: RegistroPageSite
  },
  {
    path: '/login',
    component: LoginPageSite
  },
  {
    path: '/contato',
    component: ContatoPageSite
  },
  {
    path: '/blog',
    component: BlogPage
  },
  {
    path: '/post/:slug',
    component: PostPage
  },
  {
    path: '/painel-suporte',
    component: SuportePainelPage
  },
  {
    path: '/suporte',
    component: SuportePage
  },
  
];

const router = new Router({
  routes, // ou routes: routes
  mode: 'history' // Usando o modo de histórico para URLs amigáveis
});

export default router;
