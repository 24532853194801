<template>
  <div class="dashboard-container">
    <div class="dashboard-header">
      <h1>Painel de Controle</h1>
      <p>Bem-vindo ao seu painel de controle! Abaixo estão as informações do seu cadastro e do WordPress.</p>
    </div>

    <div class="dashboard-content">
      <!-- Informações do Usuário -->
      <div class="user-info card">
        <h3>Informações do Usuário</h3>
        <p><strong>Nome:</strong> {{ user.name }}</p>
        <p><strong>Email:</strong> {{ user.email }}</p>
      </div>

      <!-- Informações do WordPress -->
      <div class="wordpress-status card">
        <h3>Informações do WordPress</h3>
        <p><strong>Status:</strong> <span :class="wordpressStatusClass"> {{ wp.status === 'aguardando' ? 'Não instalado'
          : wp.status }}</span></p>
        <p><strong>Site:</strong> {{ wp.site }}</p>
        <a v-if="wp.status == 'ativo'" class="wp-link" :href="'https://' + wp.site" target="_blank">Acessar site</a>
        <a v-if="wp.status == 'ativo'" class="wp-link" :href="'https://' + wp.site + '/wp-admin'" target="_blank">Acessar
          painel</a>
      </div>

      <!-- Informações da Plano -->
      <div class="card">
        <h3>Informações do Plano</h3>
        <p><strong>Plano:</strong> {{ contrato.plano }}</p>
        <p><strong>Termina em:</strong> {{ terminaWpGratisEm }} dias</p>
      </div>

      <!-- Instalacao do WordPress -->
      <div v-if="wp.status == 'aguardando'" class="card">
        <h3>Crie um usuário do WordPress!</h3>
        <div class="form-group">
          <label>Usuário:</label>
          <input type="text" v-model="wp.usuario" required class="form-input">
        </div>
        <div class="form-group">
          <label>Senha:</label>
          <input type="password" v-model="wp.senha" required class="form-input">
        </div>

        <button v-if="wp.status == 'aguardando'" class="wp-button" @click="installWordPress()">Instalar WordPress
          🚀</button>

      </div>

    </div>
  </div>
</template>

<script>
import { funcoesMixin } from '@/funcs/funcoesMixin';
import dayjs from "dayjs";

export default {
  name: 'HomePage',
  mixins: [funcoesMixin],
  props: {
    logadoIn: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      user: {
        name: '',
        email: ''
      },
      wp: {
        status: '',
        site: '',
        usuario: '',
        senha: ''
      },
      contrato: {},
      terminaWpGratisEm: null
    };
  },
  computed: {
    wordpressStatusClass() {
      if (this.wp.status === 'ativo') {
        return 'status-active';
      } else if (this.wp.status === 'desativo') {
        return 'status-inactive';
      } else {
        return 'status-installing';
      }
    },
  },
  mounted() {
    this.verificaSeLogou()
      .then(() => {
        const status = this.logado;
        this.$emit('atualizaLogin', status);
        if (!status) {
          this.$router.push('/login');
        }
        this.buscaInfosPessoais()
        this.buscaDadosWordpress()
        this.buscaDadosContrato()
      })
      .catch(() => {
        this.$emit('atualizaLogin', false);
        this.$router.push('/login');
      });
  },

  methods: {
    async buscaInfosPessoais() {
      try {
        const response = await fetch(`${this.apiUrl}/users/${this.id_user}?token=${this.token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {  // Verifica se a resposta é um erro (status diferente de 2xx)
          throw new Error(`Erro: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();
        this.user.name = result.data.name
        this.user.email = result.data.email

      } catch (error) {
        this.notify.show('Erro ao buscar os dados. Tente novamente!', 'error');
        console.error('Erro:', error);
      }
    },
    finalizarInstalacao() {

    },
    async installWordPress() {
      const data = {
        id_user: this.id_user,
        usuario: this.wp.usuario,
        senha: this.wp.senha
      };

      try {
        this.loadingModal.show('Instalando o seu site em WordPress...');
        const response = await fetch(`${this.apiUrl}/wordpress?token=${this.token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        if (!response.ok) {  // Verifica se a resposta é um erro (status diferente de 2xx)
          throw new Error(`Erro: ${response.status} - ${response.statusText}`);
        }
        this.notify.show('WordPress instalado com sucesso! 🚀', 'success');
        this.buscaDadosWordpress();

      } catch (error) {
        this.notify.show('Erro ao instalar WordPress. Tente novamente!', 'error');
        console.error('Erro:', error);
      } finally {
        this.loadingModal.hideModal()
      }
    },
    async buscaDadosWordpress() {
      try {
        const response = await fetch(`${this.apiUrl}/wordpress/${this.id_user}?token=${this.token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {  // Verifica se a resposta é um erro (status diferente de 2xx)
          throw new Error(`Erro: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();
        this.wp.status = result.data.status
        this.wp.site = result.data.site


      } catch (error) {
        this.notify.show('Erro ao buscar wordpress. Tente novamente!', 'error');
        console.error('Erro:', error);
      }
    },
    async buscaDadosContrato() {
      try {
        const response = await fetch(`${this.apiUrl}/contratosWp/${this.id_user}?token=${this.token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {  // Verifica se a resposta é um erro (status diferente de 2xx)
          throw new Error(`Erro: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();
        this.contrato = result.data;

        // se for um plano gratis
        if(result.data.id_plano == 7){
          this.terminaWpGratisEm = this.calcularDiasRestantes(result.data.data_criacao)
        }

      } catch (error) {
        this.notify.show('Erro ao buscar dados do contrato. Tente novamente!', 'error');
        console.error('Erro:', error);
      }
    },
    calcularDiasRestantes(data_criacao, dias_teste = 15) {
      const dataCriacao = dayjs(data_criacao).startOf("day"); // Garante que a criação seja à meia-noite
      const dataLimite = dataCriacao.add(dias_teste, "day");
      const hoje = dayjs().startOf("day"); // Também reseta o "hoje" para meia-noite

      return dataLimite.diff(hoje, "day");
    }
  },
};
</script>

<style scoped src="@/assets/css/HomePage.css"></style>
