<template>
  <div class="bg-light">
    <!-- Hero Section -->
    <section class="text-center py-5 bg-primary text-white d-flex flex-column align-items-center justify-content-center"
      style="min-height: 20vh; background: linear-gradient(135deg, #007bff, #0056b3);">
      <h1 class="display-3 font-weight-bold">Blog <span class="text-warning">WordPress</span></h1>
    </section>

    <!-- Postagem -->
    <section class="py-5 container">
      <h2 class="text-primary text-center">{{ post.title }}</h2>

      <div class="row mt-4">
        <!-- Exibindo o post -->
        <div class="col-md-12">
          <div class="border-0 p-4 card-post card.shadow-lg">
            <img :src="require(`@/assets/img/blog/${post.id}/${post.id}.png`)" class="card-img-top" :alt="post.title">
            <div class="card-body-post" v-html="post.content"></div>
          </div>
        </div>
      </div>
      <!-- Autor e Data -->
      <div class="post-meta text-end mt-3">
        <span class="author">
          <i class="fas fa-user"></i> {{ post.author }}
        </span>
        <span class="date ml-3">
          <i class="fas fa-calendar-alt"></i> {{ formatDate(post.created_at) }}
        </span>
      </div>
    </section>

    <!-- Call to Action -->
    <section class="text-center py-5 bg-primary text-white">
      <h2 class="display-5">Quer aprender mais?</h2>
      <p class="mt-3 lead">Acompanhe nosso blog e fique por dentro de tudo sobre WordPress!</p>
      <router-link to="/blog" class="btn btn-warning btn-lg mt-4">Visite o Blog</router-link>
    </section>
  </div>
</template>


<script>
import { funcoesMixin } from '@/funcs/funcoesMixin';

export default {
  name: 'PostPage',
  mixins: [funcoesMixin],
  props: {
    logadoIn: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      post: {}, // Armazena o post único
    };
  },
  mounted() {
    this.buscaPostBlog();
  },
  methods: {
    async buscaPostBlog() {
      try {
        // A URL agora usa o parâmetro 'slug' da rota para buscar um post específico
        const response = await fetch(`${this.apiUrl}/blog/${this.$route.params.slug}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`Erro: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();
        this.post = result.post;

      } catch (error) {
        this.notify.show('Não foi possível buscar o post. Tente novamente!', 'error');
        console.error('Erro:', error);
      }
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString('pt-BR', options);
    }
  },
};
</script>

<style scoped>
/* Estilo mais suave para os cards */
.card-post {
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: justify;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
}

/* Sombras mais sutis para os cards */
.card.shadow-lg {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Ajuste nas imagens dos posts */
.card-img-top {
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 40px;
}

.card-body-post h4 {
  margin-top: 40px !important;
  margin-bottom: 10px !important;
}

/* Estilos para a seção de autor e data */
.post-meta {
  font-size: 1rem;
  color: #6c757d;
}

.post-meta .author,
.post-meta .date {
  display: inline-block;
  margin-right: 20px;
}

.post-meta i {
  margin-right: 5px;
}

/* Adicionando um pouco de margem entre as informações */
.post-meta span {
  font-size: 0.9rem;
  font-weight: 500;
}

.post-meta .author {
  color: #212223;
}

.post-meta .date {
  color: #212223;
}
</style>
