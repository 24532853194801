<template>
  <div>
    <!-- Botão de abrir chat -->
     <div>
      <button @click="closeBtnSuporte()" v-if="btnSuporte && !isChatOpen" class="control-btn-suporte">×</button>
      <button id='btn-suporte' v-if="btnSuporte" v-show="!isChatOpen" @click="openChat()" class="open-chat-btn">Suporte 24h</button>      
     </div>



    <!-- Janela do chat -->
    <div v-if="isChatOpen" :class="['chat-container', { minimized: isMinimized }]">
      <!-- Cabeçalho do chat -->
      <header @click.stop="toggleMinimize()" class="chat-header">
        <h1>Chat Suporte</h1>
        <div class="window-controls">
          <button v-show="isMinimized" @click.stop="toggleMinimize()" class="control-btn minimize">ᢁ</button>
          <button v-show="!isMinimized" @click.stop="toggleMinimize()" class="control-btn minimize">−</button>
          <button @click.stop="closeChat()" class="control-btn close">×</button>

        </div>
      </header>

      <!-- Área de mensagens -->
      <main class="chat-main" v-show="!isMinimized">
        <div class="chat-messages" ref="chatMessages">
          <div v-for="(msg, index) in messages" :key="index" :class="['message', msg.isUser ? 'user' : 'other']">
            <strong>{{ msg.username }}:</strong> {{ msg.message }}
          </div>
        </div>
      </main>

      <!-- Formulário de envio de mensagens -->
      <div class="chat-form-container" v-show="!isMinimized">
        <form id="chat-form" @submit.prevent="sendMessage">
          <input v-model="localUsername" v-if="!username" type="text" placeholder="Digite seu nome" ref="usernameInput"
            required>
            <input v-model="newMessage" v-show="iniciou" :required="iniciou" type="text" placeholder="Digite uma mensagem" ref="messageInput">

          <button v-show="!iniciou" type="button" @click="iniciarChat()" class="btn">Iniciar</button>
          <button v-show="iniciou" type="submit"  class="btn">Enviar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { io } from 'socket.io-client';

export default {
  name: 'AppChat',
  data() {
    return {
      socket: null,
      messages: [
        { username: 'Nico 😸', message: 'Olá!' },
        { username: 'Nico 😸', message: 'Qual é o seu nome?' },
      ],
      username: null,
      localUsername: '',
      newMessage: '',
      isChatOpen: false,
      isMinimized: false,
      iniciou: false,
      btnSuporte: true
    };
  },
  mounted() {
    // Conecta ao servidor Socket.IO
    this.socket = io(process.env.VUE_APP_CHAT_URL, {
      transports: ['websocket'], // Para forçar o uso de WebSocket
    });

    // Recebe mensagens do servidor
    this.socket.on('new-message', (data) => {
      this.messages.push({ username: data.username, message: data.message, isUser: false });
      // Rola para o final após adicionar a mensagem
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    });

    //this.verificaSeOptouSemBtnSuporte();
  },
  methods: {
    openChat() {
      this.isChatOpen = true;
    },
    iniciarChat() {

      this.iniciou = true;
      this.username = this.localUsername;
      if(!this.username){
        this.username = 'Anônimo';
      }
      this.socket.emit('register-client', this.username);

      // Adiciona a mensagem de boas-vindas
      this.messages.push({
        username: 'Nico 😸',
        message: `${this.username}, vou te transferir para um atendente. Por enquanto, me diga como podemos te ajudar?`,
        isUser: false
      });

      // Aguarda o Vue atualizar o DOM e foca no campo de mensagem
      this.$nextTick(() => {
        this.$refs.messageInput?.focus();
      });
    },

    closeChat() {
      this.isChatOpen = false;
    },

    // Alterna o estado minimizado da janela
    toggleMinimize() {
      this.isMinimized = !this.isMinimized;
    },

    // Envia uma mensagem para o suporte
    sendMessage() {
      if (!this.newMessage.trim()) return;

      this.socket.emit('client-message', { message: this.newMessage });
      this.messages.push({ username: this.username, message: this.newMessage, isUser: true });
      this.newMessage = '';

      // Rola para o final após adicionar a mensagem
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },

    scrollToBottom() {
      const chatMessages = this.$refs.chatMessages;
      if (chatMessages) {
        chatMessages.scrollTo({
          top: chatMessages.scrollHeight,
          behavior: "smooth", // Adiciona uma animação suave
        });
      }
    },
    closeBtnSuporte() {
      this.btnSuporte = false;
      //localStorage.setItem("btnSuporteFechado", true);

    },
    verificaSeOptouSemBtnSuporte(){
      const fechado = localStorage.getItem("btnSuporteFechado");
      console.log(fechado)
      if(fechado == 'true') {
        this.btnSuporte = false;
      } else {
        this.btnSuporte = true;
      }
    }
  },
};
</script>

<style scoped>
/* Estilos gerais */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

/* Botão para abrir o chat */
.open-chat-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0073e6;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  z-index: 1000;
}

.open-chat-btn:hover {
  background-color: #005bb5;
}

/* Janela do chat */
.chat-container {
  width: 350px;
  max-width: 90%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: height 0.3s ease, width 0.3s ease;
}

/* Para telas menores (celulares) */
@media (max-width: 600px) {
  .chat-container {
    width: 100%;
    height: 90%;
    bottom: 0;
    right: 0;
    border-radius: 10px 10px 0 0;
  }
}

/* Cabeçalho do chat */
.chat-header {
  background-color: #0073e6;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
}

.chat-header h1 {
  font-size: 1.2rem;
  margin: 0;
}

/* Botões de controle da janela */
.window-controls {
  display: flex;
  gap: 5px;
}

.control-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.control-btn.close:hover {
  background-color: #ff5c5c;
}

/* Área de mensagens */
.chat-main {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f9f9f9;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 400px;
  overflow-y: auto;
}
.control-btn-suporte{
  position: fixed;
  bottom: 49px;
  right: 15px;
  background-color: #3e3f3f;
  color: #fff;
  border: none;
  padding: 1px 7px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1rem;
  z-index: 1001;
}

.control-btn-suporte:hover{
  filter: brightness(1.4);
}

/* Ajuste para celulares */
@media (max-width: 600px) {
  .chat-messages {
    max-height: 70vh;
  }
}

/* Formulário de envio de mensagens */
.chat-form-container {
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ddd;
}

#chat-form {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

#chat-form input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

#chat-form input:focus {
  outline: none;
  border-color: #0073e6;
}

#chat-form button {
  background-color: #0073e6;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

#chat-form button:hover {
  background-color: #005bb5;
}

/* Estilos das mensagens */
.message {
  padding: 8px 12px;
  border-radius: 10px;
  max-width: 70%;
  word-wrap: break-word;
  margin-bottom: 10px;
}

.message.user {
  background-color: #0073e6;
  color: #fff;
  align-self: flex-end;
}

.message.other {
  background-color: #e0e0e0;
  color: #333;
  align-self: flex-start;
}

/* Estado minimizado */
.chat-container.minimized {
  height: 40px;
}

.chat-container.minimized .chat-main,
.chat-container.minimized .chat-form-container {
  display: none;
}

/* Personalização da barra de rolagem */
.chat-messages::-webkit-scrollbar {
  width: 6px; /* Largura da barra de rolagem */
}

.chat-messages::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor de fundo da barra de rolagem */
  border-radius: 3px; /* Bordas arredondadas */
}

.chat-messages::-webkit-scrollbar-thumb {
  background: #0073e6; /* Cor da barra de rolagem (azul) */
  border-radius: 3px; /* Bordas arredondadas */
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background: #005bb5; /* Cor da barra de rolagem ao passar o mouse */
}
</style>
