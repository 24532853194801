<template>
  <div class="bg-light">
    <!-- Hero Section -->
    <section class="text-center py-5 bg-primary text-white d-flex flex-column align-items-center justify-content-center"
      style="min-height: 20vh; background: linear-gradient(135deg, #007bff, #0056b3);">
      <h1 class="display-3 font-weight-bold">Blog <span class="text-warning">WordPress</span></h1>
      <p class="mt-3 lead w-75 mx-auto" style="max-width: 700px;">Dicas, tutoriais e novidades sobre o mundo do
        WordPress. Fique por dentro e aprenda a criar o site dos seus sonhos!</p>
    </section>

    <!-- Postagens -->
    <section class="py-5 container">
      <h2 class="text-primary text-center">Últimos Posts</h2>
      <div class="row mt-4">
        <!-- Iterando sobre os posts -->
        <div class="col-md-4 my-3" v-for="post in posts" :key="post.id">
          <div class="card shadow-lg border-0 rounded-3 p-4 hover-card">
            <img :src="require(`@/assets/img/blog/${post.id}/${post.id}.png`)" class="card-img-top" :alt="post.title">
            <div class="card-body">
              <h3 class="card-title text-dark fs-5">{{ limitarConteudo(post.title, 'titulo') }}</h3>
              <p class="text-muted" v-html="limitarConteudo(post.content)"></p>
              <router-link :to="`/post/${post.url}`" class="btn btn-warning">Leia mais</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Call to Action -->
    <section class="text-center py-5 bg-primary text-white">
      <h2 class="display-5">Quer aprender mais?</h2>
      <p class="mt-3 lead">Acompanhe nosso blog e fique por dentro de tudo sobre WordPress!</p>
      <router-link to="/blog" class="btn btn-warning btn-lg mt-4">Visite o Blog</router-link>
    </section>
  </div>
</template>

<script>
import { funcoesMixin } from '@/funcs/funcoesMixin';

export default {
  name: 'HomePage',
  mixins: [funcoesMixin],
  props: {
    logadoIn: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      posts: [], // Aqui armazenamos os posts
    };
  },
  mounted() {
    this.buscaPostsBlog();
  },
  methods: {
    async buscaPostsBlog() {
      try {
        const response = await fetch(`${this.apiUrl}/blog`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`Erro: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();

        this.posts = result.posts;

      } catch (error) {
        this.notify.show('Não foi possível buscar os posts. Tente novamente!', 'error');
        console.error('Erro:', error);
      }
    },

    // Função para limitar o conteúdo do post
    limitarConteudo(conteudo, type = 'corpo') {
      let maxLength; // Define a variável maxLength fora do if

      // Define o limite de caracteres com base no tipo
      if (type === 'titulo') {
        maxLength = 50; // Define o limite para o título
      } else {
        maxLength = 200; // Define o limite para o corpo
      }

      // Verifica se o conteúdo excede o limite
      if (conteudo.length > maxLength) {
        // Encontra o último espaço antes do limite
        const ultimoEspaco = conteudo.lastIndexOf(' ', maxLength);

        // Se não houver um espaço antes do limite, corta no limite de caracteres
        const corte = ultimoEspaco === -1 ? maxLength : ultimoEspaco;

        return conteudo.slice(0, corte) + '...'; // Adiciona '...' após o corte
      }

      console.log(conteudo)

      return conteudo;
    },

  },
};
</script>

<style scoped>
/* Efeitos de hover nos cards */
.hover-card:hover {
  transform: translateY(-10px);
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
}

/* Estilo mais suave para os cards */
.card {
  border-radius: 15px;
  transition: all 0.3s ease;
  background-color: #fff;
  height: 500px;
  /* Tamanho fixo do card */
  display: flex;
  flex-direction: column;
  text-align: justify;
}

/* Sombras mais sutis para os cards */
.card.shadow-lg {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Ajuste nas imagens dos posts */
.card-img-top {
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* Limitar o conteúdo do texto no card */
.card-body p {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100px;
  /* Altura fixa para o conteúdo */
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Limita o número de linhas */
  -webkit-box-orient: vertical;
}
</style>
