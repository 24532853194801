<template>
  <!-- Usando v-if para garantir que a notificação seja montada/desmontada corretamente -->
  <div v-if="visible" :class="['notification', notificationType]" @click="hideNotification">
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: 'AppNotification',
  data() {
    return {
      visible: false,
      message: '',
      notificationType: '' // 'success' ou 'error'
    };
  },
  methods: {
    // Método para exibir a notificação
    show(message, type = 'success') {
      //alert('entrou')
      this.message = message;
      this.notificationType = type;
      this.visible = true;

      // Esconde a notificação após 3 segundos
      setTimeout(() => {
        this.visible = false;
      }, 3000);
    },
    // Método para esconder a notificação ao clicar nela
    hideNotification() {
      this.visible = false;
    }
  }
};
</script>

<style>
.notification {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  animation: fadeIn 0.5s forwards;
  z-index: 100;
}

.notification.success {
  background-color: #066e0ac9; /* Verde para sucesso */
}

.notification.error {
  background-color: #e72315c8; /* Vermelho para erro */
}

.notification p {
  margin: 0;
}

/* Quando a notificação estiver visível, definimos a opacidade para 1 */
.notification.visible {
  opacity: 1; /* A opacidade muda para 1 (visível) */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
