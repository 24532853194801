<template>
  <div class="bg-light">
    <!-- Hero Section -->
    <section class="text-center py-1 bg-primary text-white d-flex flex-column align-items-center justify-content-center"
      style="min-height: 20vh; background: linear-gradient(135deg, #007bff, #0056b3);">
      <h1 class="display-3 font-weight-bold">Suporte</h1>
    </section>

    <!-- Categorias -->
    <div class="container py-5">
      <h2 class="text-center mb-4">Categorias</h2>
      <div class="row">
        <div class="col-md-4" v-for="categoria in categorias" :key="categoria.id">
          <div class="card shadow-sm p-3 mb-4">
            <h5 class="card-title text-dark">{{ categoria.nome }}</h5>
            <ul class="list-unstyled">
              <li v-for="topico in categoria.topicos" :key="topico.id">
                <router-link :to="topico.link" class="text-primary">{{ topico.nome }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <h3 class="text-center mb-4">Não achou o que procura?</h3>
        <h4 class="text-end py-1">Use o chat 👇</h4>

      </div>
    </div>

    <!-- Chat de Suporte -->
    <div>
      <AppChat ref="chatSuporte" />
    </div>
  </div>
</template>

<script>
import { funcoesMixin } from '@/funcs/funcoesMixin';
import AppChat from '@/components/AppChat.vue';

export default {
  name: "SuportePage",
  mixins: [funcoesMixin],
  components: {
    AppChat
  },
  data() {
    return {
      categorias: [
        {
          id: 1,
          nome: "WordPress",
          topicos: [
            { id: 1, nome: "Como criar um site usando WordPress?", link: "/post/como-criar-um-site-no-wordpress-do-zero" }
          ]
        },
        {
          id: 2,
          nome: "Segurança",
          topicos: [
            { id: 3, nome: "Como proteger meu site?", link: "post/melhores-praticas-de-segurança-no-wordress" }
          ]
        },
        {
          id: 3,
          nome: "Conta e Configurações",
          topicos: [
            { id: 1, nome: "Criar nova conta", link: "/registro" }
          ]
        }
      ]
    };
  },
  mounted() {
    this.abreChatSuporte()
    this.$refs.chatSuporte.btnSuporte = false;
    //this.$refs.chatSuporte.isChatOpen = true;
    localStorage.removeItem("btnSuporteFechado");
  },
  methods: {
    abreChatSuporte() {
      if (this.$refs.chatSuporte.btnSuporte) {
        this.criaChat();
        this.$refs.chatSuporte.btnSuporte = false
      }

      console.log(this.$refs.chatSuporte.isChatOpen); // Acessa a variável de AppChat
    }
  },
};
</script>

<style scoped src="@/assets/css/SuportePage.css"></style>
