<template>
  <div id="app">
    <header>
      <nav>
        <ul>
          <li><router-link to="/">Inicio</router-link></li>
          <li><router-link to="/blog">Blog</router-link></li>
          <li><router-link to="/suporte">Suporte</router-link></li>
          <li v-if="!logadoIn"><router-link to="/login">Login</router-link></li>
          <li v-if="!logadoIn"><router-link to="/registro">Registrar</router-link></li>
          <li v-if="logadoIn"><router-link to="/painel">Painel</router-link></li>
          <li v-if="logadoIn"><button @click="logout">Sair</button>
          </li>
        </ul>
      </nav>
    </header>

    <router-view :logadoIn="logadoIn" @atualizaLogin="atualizaLogin"></router-view>
  </div>
</template>

<script>
import { funcoesMixin } from '@/funcs/funcoesMixin';

export default {
  name: 'App',
  mixins: [funcoesMixin],
  data() {
    return {
      logadoIn: false
    };
  },
  mounted() {
    this.verificaSeLogou()
      .then(() => {
        this.logadoIn = this.logado;
      })
      .catch(() => {
        this.logadoIn = false;
      });

      this.criaChat();
  },
  methods: {
    async logout() {
      const token = sessionStorage.getItem('authToken');
      try {
        const response = await fetch(
          `${this.apiUrl}/logout?token=${token}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Erro ao fazer logout');
        }

        sessionStorage.removeItem('authToken');
        this.logadoIn = false;
        this.notify.show('Logout realizado com sucesso!', 'success');
        
        // Aguarda 1 segundo antes de redirecionar
        setTimeout(() => {
          this.$router.push('/login');
        }, 1000); // 1000ms = 1 segundo
      } catch (error) {
        console.error('Erro ao realizar logout:', error);
        this.notify.show('Erro ao deslogar!', 'error');
      }
    },
    atualizaLogin(status) {
      this.logadoIn = status;
    },
  },
};
</script>

<style src="@/assets/css/App.css"></style>
