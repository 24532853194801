<template>
  <div id="telaLogin" class="d-flex justify-content-center align-items-center min-vh-100 bg-dark">
    <div class="login-container bg-white p-4 rounded shadow-lg">
      <h1 class="text-center text-dark mb-4">Acesse sua conta</h1>
      <form @submit.prevent="login">
        
        <div class="form-group">
          <label for="email" class="text-dark">E-mail</label>
          <input 
            type="email" 
            v-model="formLogin.email" 
            required 
            class="form-control" 
            placeholder="Digite seu e-mail"
            id="email"
          >
        </div>

        <div class="form-group">
          <label for="password" class="text-dark">Senha</label>
          <input 
            type="password" 
            v-model="formLogin.password" 
            required 
            class="form-control" 
            placeholder="Digite sua senha" 
            id="password"
          >
        </div>

        <button type="submit" class="btn btn-primary w-100 mt-4">Acessar</button>
      </form>
    </div>
  </div>
</template>

<script>
/* Importações */
import { funcoesMixin } from '@/funcs/funcoesMixin';

/* JavaScript */
export default {
  name: 'LoginPage',
  mixins: [funcoesMixin],
  data() {
    return {
      formLogin: {
        email: '',
        password: ''
      },
    };
  },
  mounted() {
    this.verificaSeLogou().then(() => {
      // Verificação de login
      if (this.logado) {
        console.log('Usuário está logado, redirecionando para o painel...');
        this.$router.push('/painel');
      } else {
        console.log('Usuário não está logado');
      }
    }).catch((error) => {
      console.error('Erro ao verificar o login:', error);
    });
  },
  methods: {
    async login() {
      const data = {
        email: this.formLogin.email,
        password: this.formLogin.password
      };

      try {
        const response = await fetch(`${this.apiUrl}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {  // Verifica se a resposta é um erro (status diferente de 2xx)
            throw new Error(`Erro: ${response.status} - ${response.statusText}`);
        }
        this.loading.show();
        const result = await response.json();
        sessionStorage.setItem('authToken', result.token);
        this.id_user = result.user_id;
        this.notify.show('Login realizado com sucesso!', 'success');
        
        // Aguarda 1 segundo antes de redirecionar
        setTimeout(() => {
          this.$router.push('/painel');
          this.loading.hideModal();
        }, 1000); // 1000ms = 1 segundo
      } catch (error) {
        this.loading.hideModal();
        // Usando a instância de notificação para mostrar erro
        this.notify.show('Erro ao realizar login. Tente novamente!', 'error');
        console.error('Erro:', error);
      }
    }
  }
};
</script>

<style scoped>
#telaLogin {
  background: linear-gradient(135deg, #007bff, #6c757d); /* Gradiente para fundo */
}

.login-container {
  width: 100%;
  max-width: 380px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  background-color: #e3e3e3;
}

h1 {
  font-size: 26px;
  font-weight: 500;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  font-size: 16px;
}

button {
  padding: 14px;
  font-size: 16px;
  font-weight: bold;
}

button:hover {
  background-color: #0056b3;
}

</style>
