<template>
  <div class="support-container">
    <!-- Cabeçalho -->
    <header class="support-header">
      <h1>Painel de Suporte</h1>
    </header>

    <!-- Corpo principal -->
    <main class="support-main">
      <!-- Lista de clientes conectados -->
      <div class="client-list">
        <h2>Clientes Conectados</h2>
        <ul>
          <li v-for="client in clients" :key="client.id" @click="selectClient(client)"
            :class="{ active: selectedClientId === client.id }">
            {{ client.username }} (ID: {{ client.id }})
          </li>
        </ul>
      </div>

      <!-- Janela do chat -->
      <div class="chat-container">
        <div class="chat-messages">
          <div v-for="(msg, index) in chatMessages" :key="index" :class="['message', msg.isSupport ? 'user' : 'other']">
            <small>{{ new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }} -
            </small>
            <strong>{{ msg.username }}:</strong> {{ msg.message }}
          </div>
        </div>

        <!-- Formulário de envio -->
        <div class="chat-form-container">
          <form @submit.prevent="sendMessage">
            <input v-model="messageInput" type="text" placeholder="Digite uma mensagem" required />
            <button type="submit">Enviar</button>
          </form>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { io } from "socket.io-client";

export default {
  name: "SuportePainelPage",
  data() {
    return {
      socket: null,
      clients: [],
      selectedClientId: null,
      chatHistories: {},
      chatMessages: [],
      messageInput: "",
      messageSound: new Audio(require('@/assets/music/new-msg.mp3')),
    };
  },
  async created() {
    // Carregar histórico de chat do servidor
    await this.loadChatHistory();
    // Inicializa o socket
    this.server();
  },
  methods: {
    // Carrega o histórico de chat do servidor
    async loadChatHistory() {
      try {
        const response = await fetch(`${process.env.VUE_APP_CHAT_URL}/chat-history`);
        const text = await response.text(); // Obtém como string primeiro

        const data = JSON.parse(text); // Converte para JSON

        // Armazena o histórico
        this.chatHistories = data;

        // Atualiza a lista de clientes
        this.clients = Object.keys(data).map((clientId) => ({
          id: clientId,
          username: data[clientId].username || "Cliente",
        }));

        // Se houver clientes, seleciona o primeiro e carrega o chat
        if (this.clients.length > 0) {
          this.selectedClientId = this.clients[0].id;
          this.updateChatMessages(this.selectedClientId);
        }
      } catch (error) {
        console.error("Erro ao carregar o histórico de conversas:", error);
      }
    },

    // Atualiza as mensagens do chat
    updateChatMessages(clientId) {
      if (this.chatHistories[clientId]) {
        this.chatMessages = this.chatHistories[clientId].messages.map((msg) => ({
          ...msg,
          username: this.chatHistories[clientId].username || "Cliente", // Garante que o nome aparece
        }));
      } else {
        this.chatMessages = [];
      }
    },

    // Inicializa a conexão com o servidor WebSocket
    server() {
      this.socket = io(process.env.VUE_APP_CHAT_URL, {
        transports: ["websocket"],
      });

      // Quando um novo cliente se conecta
      this.socket.on("new-client", (client) => {
        this.clients.push(client);
        if (!this.chatHistories[client.id]) {
          this.chatHistories[client.id] = { messages: [] }; // Garante que a estrutura esteja correta
        }
      });

      // Quando uma nova mensagem é recebida de um cliente
      this.socket.on("new-client-message", (data) => {
        if (!this.chatHistories[data.clientId]) {
          this.chatHistories[data.clientId] = { messages: [] }; // Garante que a estrutura esteja correta
        }

        // Adiciona a nova mensagem ao histórico
        this.chatHistories[data.clientId].messages.push({
          username: data.username,
          message: data.message,
          isSupport: false,
          timestamp: new Date().toISOString(),
        });

        // Se o cliente selecionado for o mesmo que o novo cliente, atualiza as mensagens
        if (data.clientId === this.selectedClientId) {
          this.chatMessages = [...this.chatHistories[data.clientId].messages];
        }

        // Toca o som quando uma nova mensagem chegar
        this.messageSound.play();
      });

      // Quando um cliente se desconecta
      this.socket.on("client-disconnected", (client) => {
        this.clients = this.clients.filter((c) => c.id !== client.id);
        delete this.chatHistories[client.id];

        if (this.selectedClientId === client.id) {
          this.selectedClientId = null;
          this.chatMessages = [];
        }
      });
    },

    // Seleciona um cliente para conversar
    selectClient(client) {

      this.selectedClientId = client.id;


      // Garantir que a estrutura de mensagens está carregada para o cliente selecionado
      if (!this.chatHistories[client.id]) {
        // Armazenando tanto o username quanto as mensagens
        this.chatHistories[client.id] = {
          username: client.username || "Cliente", // Garantindo que o nome esteja sempre disponível
          messages: []
        };
      }

      // Atualiza as mensagens do chat
      this.chatMessages = [...this.chatHistories[client.id].messages];
    },


    // Envia uma mensagem para o cliente selecionado
    sendMessage() {
      if (!this.selectedClientId || !this.messageInput.trim()) return;

      const message = this.messageInput.trim();
      this.socket.emit("support-message", {
        clientId: this.selectedClientId,
        message,
      });

      // Adiciona a mensagem ao histórico
      if (!this.chatHistories[this.selectedClientId]) {
        this.chatHistories[this.selectedClientId] = { messages: [] };
      }

      this.chatHistories[this.selectedClientId].messages.push({
        username: "Suporte",
        message,
        isSupport: true,
        timestamp: new Date().toISOString(),
      });

      // Atualiza as mensagens no chat
      this.chatMessages = [...this.chatHistories[this.selectedClientId].messages];
      this.messageInput = ""
    },
  },

  beforeUnmount() {
    if (this.socket) {
      this.socket.disconnect();
    }
  },
};
</script>

<style scoped src="@/assets/css/SuportePage.css"></style>
