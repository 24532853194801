<template>
  <div id="telaRegistro">
    <div class="card p-2 p-md-5 shadow-lg rounded-3" style="max-width: 500px; width: 100%;">
      <h1 class="text-center mb-4">Crie seu site agora!</h1>
      <form @submit.prevent="registrar">
        <!-- Campo Nome -->
        <div class="mb-3">
          <input
            type="text"
            v-model="formData.nome"
            class="form-control form-control-lg"
            placeholder="Nome Completo"
            @input="validateField('nome')"
            @focus="clearPlaceholder"
            @blur="restorePlaceholder('Nome Completo')"
            :class="{ 'is-invalid': errors.nome, 'is-valid': !errors.nome && formData.nome }"
          >
          <div v-if="errors.nome" class="invalid-feedback">{{ errors.nome }}</div>
        </div>

        <!-- Campo Email -->
        <div class="mb-3">
          <input
            type="email"
            v-model="formData.email"
            class="form-control form-control-lg"
            placeholder="Email"
            @input="validateField('email')"
            @focus="clearPlaceholder"
            @blur="restorePlaceholder('Email')"
            :class="{ 'is-invalid': errors.email, 'is-valid': !errors.email && formData.email }"
          >
          <div v-if="errors.email" class="invalid-feedback">{{ errors.email }}</div>
        </div>

        <!-- Campo Senha -->
        <div class="mb-3">
          <input
            type="password"
            v-model="formData.password"
            class="form-control form-control-lg"
            placeholder="Senha"
            @input="validateField('password')"
            @focus="clearPlaceholder"
            @blur="restorePlaceholder('Senha')"
            :class="{ 'is-invalid': errors.password, 'is-valid': !errors.password && formData.password }"
          >
          <div v-if="errors.password" class="invalid-feedback">{{ errors.password }}</div>
        </div>

        <!-- Campo Site -->
        <div class="mb-3">
          <div class="input-group">
            <input
              type="text"
              v-model="formData.site"
              class="form-control form-control-lg"
              placeholder="seusite"
              @input="validateField('site')"
              @focus="clearPlaceholder"
              @blur="restorePlaceholder('seusite')"
              :class="{ 'is-invalid': errors.site, 'is-valid': !errors.site && formData.site }"
            >
            <span class="input-group-text">.gympro.com.br</span>
          </div>
          <div v-if="errors.site" class="invalid-feedback">{{ errors.site }}</div>
        </div>

        <!-- Botão Submit -->
        <div class="d-grid">
          <button type="submit" class="btn btn-primary btn-lg">Criar agora</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* Importações */
import { funcoesMixin } from '@/funcs/funcoesMixin';

export default {
  name: 'RegistroPage',
  mixins: [funcoesMixin],
  data() {
    return {
      formData: {
        nome: '',
        email: '',
        password: '',
        site: ''
      },
      errors: {
        nome: '',
        email: '',
        password: '',
        site: ''
      }
    };
  },
  methods: {
    /* Limpa o placeholder ao focar no campo */
    clearPlaceholder(event) {
      event.target.placeholder = '';
    },

    /* Restaura o placeholder ao sair do campo */
    restorePlaceholder(placeholder) {
      if (!event.target.value) {
        event.target.placeholder = placeholder;
      }
    },

    /* Validação de um campo específico */
    async validateField(field) {
      switch (field) {
        case 'nome':
          if (!this.formData.nome) {
            this.errors.nome = 'O campo nome é obrigatório.';
          } else if (this.formData.nome.length > 255) {
            this.errors.nome = 'O nome não pode ter mais de 255 caracteres.';
          } else {
            this.errors.nome = '';
          }
          break;

        case 'email':
          if (!this.formData.email) {
            this.errors.email = 'O campo e-mail é obrigatório.';
          } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.formData.email)) {
            this.errors.email = 'O e-mail informado é inválido.';
          } else if (this.formData.email.length > 50) {
            this.errors.email = 'O e-mail não pode ter mais de 50 caracteres.';
          } else {
            this.errors.email = '';

            const disponivel = await this.verificaEmailDisponivel(this.formData.email);
            if (!disponivel) {
              this.errors.email = 'Este e-mail já está cadastrado. Tente outro ou recupere sua conta';
            }
          }
          break;

        case 'password':
          if (!this.formData.password) {
            this.errors.password = 'O campo senha é obrigatório.';
          } else if (this.formData.password.length < 8) {
            this.errors.password = 'A senha deve ter pelo menos 8 caracteres.';
          } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(this.formData.password)) {
            this.errors.password = 'A senha deve conter pelo menos uma letra minúscula, uma letra maiúscula e um número.';
          } else {
            this.errors.password = '';
          }
          break;

        case 'site':
          if (!this.formData.site) {
            this.errors.site = 'O campo site é obrigatório.';
          } else if (this.formData.site.length < 3) {
            this.errors.site = 'O site deve ter pelo menos 3 caracteres.';
          } else if (!/^[a-z]+$/.test(this.formData.site)) {
            this.errors.site = 'O site deve conter apenas letras minúsculas.';
          } else {
            this.errors.site = '';

            const disponivel = await this.verificaSiteDisponivel(this.formData.site);
            if (!disponivel) {
              this.errors.site = 'O site não está disponível. Use outro.';
            }
          }
          break;
      }
    },

    /* Registrar um novo usuário */
    async registrar() {
      Object.keys(this.formData).forEach(field => this.validateField(field));

      if (Object.values(this.errors).some(error => error !== '')) {
        this.notify.show('Corrija os erros antes de continuar.', 'error');
        return;
      }
      this.loading.show();

      const data = {
        name: this.formData.nome,
        email: this.formData.email,
        password: this.formData.password,
        site: this.formData.site
      };

      try {
        const response = await fetch(`${this.apiUrl}/register`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          throw new Error(`Erro: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();
        sessionStorage.setItem('authToken', result.token);
        this.notify.show('Registro realizado com sucesso!', 'success');

        // Aguarda 1 segundo antes de redirecionar
        setTimeout(() => {
          this.loading.hideModal();
          this.$router.push('/painel');
        }, 1000);
      } catch (error) {
        this.notify.show('Erro ao realizar o registro.', 'error');
      } finally {
        this.loading.hideModal();
      }
    },

    /* Verifica a disponibilidade do site */
    async verificaSiteDisponivel(site) {
      try {
        const response = await fetch(`${this.apiUrl}/verificaSiteDisponivel/${site}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        });

        const result = await response.json();
        return result.status === 'success';
      } catch (error) {
        this.notify.show('Erro ao verificar disponibilidade do site.', 'error');
      }
    },

    /* Verifica a disponibilidade do email */
    async verificaEmailDisponivel(email) {
      try {
        const response = await fetch(`${this.apiUrl}/verificaEmailDisponivel/${email}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        });

        const result = await response.json();
        return result.status === 'success';
      } catch (error) {
        this.notify.show('Erro ao verificar disponibilidade do email.', 'error');
      }
    }
  }
};
</script>

<style scoped>
/* Estilos gerais */
#telaRegistro {
  height: 100vh; /* Ocupa 100% da altura da tela */
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #333, #444); /* Gradiente de fundo mais neutro */
}
.card {
  background-color: #e3e3e3;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 26px;
  font-weight: 500;
}

.btn-primary {
  background-color: #4caf50;
  border-color: #4caf50;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-primary:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

/* Estilo para campos com sucesso e erro */
.is-invalid {
  border-color: #dc3545 !important;
}

.is-valid {
  border-color: #28a745 !important;
}
</style>
