<template>
    <div v-if="visible" class="modal-overlay">
        <img src="@/assets/loading.webp" alt="Carregando..." class="loading-image" />
    </div>
</template>

<script>
export default {
    name: 'AppLoading',
    data() {
        return {
            visible: false,
        };
    },
    methods: {
        show() {
            this.visible = true;
        },
        hideModal() {
            this.visible = false;
        }
    }
};
</script>

<style scoped>
/* Overlay escuro */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.47);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

/* Imagem de carregamento */
.loading-image {
    width: 100px;
    height: auto;
}
</style>
