import { render, staticRenderFns } from "./SuportePainelPage.vue?vue&type=template&id=07a0a2d1&scoped=true"
import script from "./SuportePainelPage.vue?vue&type=script&lang=js"
export * from "./SuportePainelPage.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/SuportePage.css?vue&type=style&index=0&id=07a0a2d1&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07a0a2d1",
  null
  
)

export default component.exports