import Vue from 'vue';
import AppNotification from '@/components/AppNotification.vue';
import AppModalCarregamento from '@/components/AppModalCarregamento.vue';
import AppLoading from '@/components/AppLoading.vue';
import AppChat from '@/components/AppChat.vue';

export const funcoesMixin = {
  data() {
    return {
      notify: null,
      loadingModal: null, // Instância do modal de carregamento
      loading: false,
      logado: false,
      token: null,
      id_user: null,
      apiUrl: process.env.VUE_APP_API_URL
    };
  },
  mounted() {
    this.criaNotification();
    this.criaModalCarregamento();
    this.criaLoading();
  },
  methods: {
    async verificaSeLogou() {
      let token = sessionStorage.getItem('authToken');
      this.token = token;

      if (!token) {
        this.logado = false;
        return false;
      }

      try {
        const response = await fetch(`${this.apiUrl}/verificaToken?token=${token}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`Erro: ${response.status} - ${response.statusText}`);
        }

        const result = await response.json();
        this.id_user = result.user_id;
        this.logado = true;
        return true;
      } catch (error) {
        console.error('Erro ao verificar login:', error);
        this.logado = false;
        return false;
      }
    },
    criaNotification() {
      const NotificationConstructor = Vue.extend(AppNotification);
      this.notify = new NotificationConstructor();

      const container = document.createElement('div');
      document.body.appendChild(container);

      this.notify.$mount(container);
    },
    criaModalCarregamento() {
      const ModalConstructor = Vue.extend(AppModalCarregamento);
      this.loadingModal = new ModalConstructor();

      const container = document.createElement('div');
      document.body.appendChild(container);

      this.loadingModal.$mount(container);
    },
    showLoading(message = 'Carregando...') {
      if (this.loadingModal) {
        this.loadingModal.show(message);
      }
    },
    hideLoading() {
      if (this.loadingModal) {
        this.loadingModal.hideModal();
      }
    },
    showNotification(message, type = 'success') {
      if (this.notify) {
        this.notify.show(message, type);
      }
    },
    criaLoading() {
      const LoadingConstructor = Vue.extend(AppLoading);
      this.loading = new LoadingConstructor();

      const containera = document.createElement('div');
      document.body.appendChild(containera);

      this.loading.$mount(containera);
    },
    criaChat() {
      const ChatConstructor = Vue.extend(AppChat);
      this.chat = new ChatConstructor();

      const containeraa = document.createElement('div');
      document.body.appendChild(containeraa);

      this.chat.$mount(containeraa);
    }
  },
  beforeDestroy() {
    // Desmonta os componentes para evitar vazamentos de memória
    if (this.notify) {
      this.notify.$destroy();
      document.body.removeChild(this.notify.$el);
    }
    if (this.loadingModal) {
      this.loadingModal.$destroy();
      document.body.removeChild(this.loadingModal.$el);
    }
  }
};